
    
.App {

  display:flex;
  width:100%;
  min-height:100%;

    
    .sidebar {
      
      
      width:100%;
      max-width:20rem;
      border-right: 1px solid #33373c;
      z-index: 500;
    }
    
    
    // .main-content {
    
      
    //   width: 85%;
      
    //   overflow: hidden;
      
    //   //background-image:url(../img/Wavey.svg)
    //   position: relative;

      
    // }
    
  }
  @media (max-width:480px) {
    .sidebar{
      display: none;
    }
    .App{
      align-items: center;
      justify-content: center;
      
     
    }
    
    
    
   
    
  }
 