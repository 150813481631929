.not-found-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100vh;

    .number-error{
        font-size: 10rem;
        
        color:#60bbd0;
    }
    .error-type{
        max-width: 100%;
        font-size:5rem;
    }
    

}

@media (max-width:480px) {

    .not-found-container{
        
    
        .number-error{
            font-size: 5rem;
            
        }
        .error-type{
            max-width: 100%;
            font-size:2.5rem;
        }
        
    
    }

}