.about-page{
    width:100%;
    display:flex;
    align-items: center;
    position: relative;
    flex-direction: column;
    padding:1.5rem;
    

    
}