.HomePage {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 80%;
  width: 100%;
  min-height:100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  
    

  
  .hero {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  
    .hero-text {
      width: 100%;
      max-width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
  
      .jump-text {
        position: relative;
      }
      .jump-text span {
        position: relative;
        display: inline-block;
        animation: animatejump .7s ease-in-out forwards;
        opacity: 0%;
      }
      @keyframes animatejump {
        0% {
          transform: translateY(0px);
        }
        20% {
          transform: translateY(-20px);
        }
        40%,100% {
          transform: translateY(0px);
          opacity:1;
        }
      }
  
      .jump-text span:nth-child(1){
          animation-delay:.1s;
      }
      .jump-text span:nth-child(2){
          animation-delay:.2s;
      }
      .jump-text span:nth-child(3){
          animation-delay:.3s;
      }
      .jump-text span:nth-child(4){
          animation-delay:.4s;
      }
      .jump-text span:nth-child(5){
          animation-delay:.5s;
      }
      .jump-text span:nth-child(6){
          animation-delay:.6s;
      }
  
      color: white;
      text-align: center;
      font-size: 3.6rem;
      .slide-name {
        color: #60bbd0;
        animation: left-to-right .5s ease-in forwards;
        opacity:0%;
        animation-delay:.9s;
         
      }
      @keyframes left-to-right {
          0%{
              transform:translateX(-200px);
          }
          100%{
              transform:translateX(0px);
              opacity: 1;
          }
  
          
      }
      
      @keyframes opacity-effect {
  
          0%{
              opacity: 0%;
  
          }
          100%{
              
              opacity: 1;
              
          }
      }
    }
  }
  
  .h-sub-text {
  animation: opacity-effect .5s ease-in forwards;
    margin-top: 15px;
    text-align: center;
    animation-delay:1.5s;
    opacity:0%;
    color: #fafbfc;
  }
  .icons {
     animation: opacity-effect .5s ease-in forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
    animation-delay:1.7s;
    opacity:0%;
    .icon-holder {
      margin-right: 1rem;
  
      .icon {
        font-size: 2.8rem;
        color: #fafbfc;
        transition: all 0.3s ease-in-out;
      }
      .lin:hover {
        color: #0a66c2;
      }
      .gh:hover {
        color: #8a63d2;
      }
    }
  }
  
  
  @media (max-width:829px){
    .hero{
      .hero-text{
        display: flex;
        flex-direction: column;
      }
  
    }
  }
}


// @media (max-width:480px) {
//   .HomePage{
//     height:100vh;
    
//   }
 
  
// }