// .contact-container{
//     display:flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap:2rem;
//     width:100%;
//     max-width: 70rem;



    .contact-card-container{
        display:flex;
        justify-content: space-evenly;
        width:100%;
        max-width: 75rem;
        background-color: #2a2c2c;

        color: #60bbd0;
        border-radius:2em;
        z-index:10;
        flex-grow: 1;
        

     
           
    }
    
    
    
    
   // }

    @media (max-width:480px){
        // .contact-container{
        //     margin-top: 10rem;
            .contact-card-container{
                flex-direction: column;
            }

        //}

    }
