
*,
*::before,
*::after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}
html, #root{
  height:100%;

}   
body{
     background: linear-gradient(to top right,#171717 50%, #1e2124 ) no-repeat fixed;;
    width:100%;
    overflow: auto;
    height: 100%;

    font-family: 'Montserrat', sans-serif;
    font-size:1.2rem;
    color:aliceblue;
    

    
   


}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

