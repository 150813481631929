.NavBar{
    height:100vh;
    .nav{
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content:space-between;
        align-items:center;
    
    }
    .profile{
        width: 100%;
        text-align:center;
        margin-top:1rem;
        border-bottom:1px solid #171717;
        
        .name-container{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap:1rem;
            padding:2rem;
            .joshua-container{
                display:flex;
                img{
                    width: 100%;
                    height:auto;
                   
                    border-radius: 45%;
                    border:1px solid #171717;
                    margin-bottom:1rem;
                }

               
            }
            .web-developer{
                font-size: 1.5rem;

            }
            border-bottom:1px solid #33373c;
            // background: radial-gradient(circle at center,  #60bcd034 0%,#60bcd033 30%,#60bcd034 30%,#171717 47%,#171717 48%);
        }
    }
    .nav-items{

        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width:100%;
       
        // gap:2rem;
        .nav-item{
            list-style:none;
            text-align: center;
            position:relative;
            color: #fafbfc;
            width:100%;
            font-size:1.5rem;
            
            
   
            a{
                text-decoration:none;
                font-size:inherit;
                color:inherit;
                display:block;
                padding: .8rem 0;
                position:relative;
                
                &::before{
                    content:"";
                    position:absolute;
                    top:0;
                    left:0;
                    height:100%;
                    background-color: #60bcd071;
                    
                   
                    transform-origin:center;
                    overflow-x:hidden;
                    transition:transform .6s, .2s width .5s cubic-bezier(1,-0.16,.6,1.35);
                }
                &:hover:not(.active)::before{
                    animation: button-hover 1s forwards;

                    
                }
                @keyframes button-hover {
                    0%{
                        width:100%;
                        opacity:0%;
                        
                    }
                    100%{
                        width:100%;
                        opacity:1;
                        
                    }
                    
                }
                



            }
        }

    }
        .footer{
            width:100%;
            border-top:1px solid #33373c;
            color: #fafbfc;
            p{
                text-align:center;
                padding:1rem 0;
               
            }
           
        }


}
.active{
   background-color: #60bbd0;
    
    
  
    
}
