
.Title{
    text-transform: uppercase;
    font-size: 2rem;
    color:white;
    padding-bottom:1rem;
    letter-spacing:3px;
    animation: opacity-effect .5s ease-in forwards;
    opacity: 0%;
    align-self: start;
   
   
    
   
    h1{
       
        margin-left: 50px;
        font-size:5rem;
        opacity:0.07;
        position: relative;
    
        &::after{
            content:"";
            position:absolute;
            bottom: 0;
            left:0;
            background-color:#60BBD0;
            width:0rem;
            height:.4rem;
            border-radius:50px;
            animation: line-stretch 1s ease-in-out forwards;
            
            
        }
        @keyframes line-stretch {
            0%{
                width:0rem;
                
            }
            
            100%{
                width:30rem;
            }
            
        }
            
        }
    }
    @media (max-width:890px){
        .Title{
            span{
                font-size:4rem;
                margin-top: 5rem;
                margin-left: 0px;
                &::after{
                    content:"";
                    position:absolute;
                    bottom: 0;
                    left:0;
                    background-color:#60BBD0;
                    width:0rem;
                    
                    animation: line-stretch-shrink-ipad 1s ease-in-out forwards;
                    
                }


            }


        }
        @keyframes line-stretch-shrink-ipad {
            0%{
                width:0rem;
            }
            100%{
                width:25rem;
            }
            
        }

    }
    
    @media (max-width:480px){
        .Title{
            h1{
                font-size:3rem;
                margin-top: 3rem;
                margin-left: 0px;
                &::after{
                    content:"";
                    position:absolute;
                    bottom: 0;
                    left:0;
                    background-color:#60BBD0;
                    width:0rem;
                    
                    animation: line-stretch-shrink 1s ease-in-out forwards;
                    
                }


            }


        }
        @keyframes line-stretch-shrink {
            0%{
                width:0rem;
            }
            100%{
                width:20rem;
            }
            
        }

    }




