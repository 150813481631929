




    .about-section{


        display:flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding:1em;
    flex-grow: 1;

    
   
   
     

    .hello-h{
        color:#60BBD0;
        opacity:0%;
        transform-origin: left top;
        animation: .5s hello-animation ease-in-out forwards;
        font-size:50px;
        letter-spacing: 2px;
        align-self: left;
        
    }
    @keyframes hello-animation {
        0%{
            opacity: 0%;

            transform: scale(1);
        }
        50%{
            transform: scale(1.2);
            opacity: 50%;
        }
        100%{
            transform: scale(1);
            opacity: 1;
        }
    }
    
    
     
    }
    
    .about-info{
        
        animation: .8s about-animation ease-out forwards;
        animation-delay: .5s;
        opacity: 0%;
        margin-top:2rem;
        font-size: 24px;
        line-height: 33.2px;
        max-width: 75ch;
        
        h4{
            font-size:2rem;
        }
        .about-text{
            padding: 1rem 0;
        }
    }

    @keyframes about-animation {
        0%{
            
            transform:translateX(-200px);
        }
        100%{
            transform:translateX(0px);
            opacity: 1;
        }
        
    }
    .tech-list{
        margin-top:2rem;
        h3{
            opacity: 0%; 
            animation:.5s left-to-right forwards ease-in;
            animation-delay: 1s;
            color:#60BBD0;
            font-size:26px;
            letter-spacing: 2px;
        }
    display:flex;
    width:100%;
    flex-direction: column;
    align-items: left;
    justify-content: center;
   
    li{
       
        list-style: none;
        margin-top:.5rem;
        font-size:20px;

    }
    li:nth-child(1){
        opacity: 0%; 
        animation:.5s left-to-right forwards ease-out;
        animation-delay: 1.5s;
        

    }
    li:nth-child(2){
        opacity: 0%; 
        animation:.5s left-to-right forwards ease-out;
        animation-delay: 1.6s;
        

    }
    li:nth-child(3){
        opacity: 0%; 
        animation:.5s left-to-right forwards ease-out;
        animation-delay: 1.7s;
        

    }
    li:nth-child(4){
        opacity: 0%; 
        animation:.5s left-to-right forwards ease-out;
        animation-delay: 1.8s;
        

    }
    li:nth-child(5){
        opacity: 0%; 
        animation:.5s left-to-right forwards ease-out;
        animation-delay: 1.9s;
        

    }
    li:nth-child(6){
        opacity: 0%; 
        animation:.5s left-to-right forwards ease-out;
        animation-delay: 2s;
        

    }

    }
    .resume-request{
        margin-top:2rem;
        opacity: 0%;
        animation:.5s left-to-right forwards ease-out;
        animation-delay: 2.3s;
    }
.button-c{
    opacity: 0%;
    animation: .5s hello-animation forwards;
    animation-delay: 2.6s;
    cursor:default;
    margin-top: 40px;
    
    .btn{
   
        
        
       
        padding:1rem 2rem;
        align-self: center;
        width:100%;
        // max-width: 100rem;
        background-color: #60BBD0;
        outline:none;
        border:none;
        font-family:inherit;
        font-size:inherit;
        color:white;
        text-transform:uppercase;
        letter-spacing:2px;
        cursor: pointer;
        border-radius: 1em;
    
    
    
    }
    .btn:hover{
        animation: .2s button-animation forwards;
       
    }

}




@keyframes button-animation {
    0%{
        
      
        transform: scale(1);
    }
   
    100%{
        transform: scale(1.02);
        background-color: #71b5c4;
        
    }
}
@media (max-width:480px){
    .about-section{
        font-size:15px;

    }

}
