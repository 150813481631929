.info-section{
    display: flex;
    flex-direction: column;
    width:100%;
    max-width: 50%;
    padding:1.5em;
    color:#2a2c2c;
    background-color: #60bbd0;
    border-top-right-radius:2em;
    border-bottom-right-radius: 2em;
    row-gap: 4rem;

    .info-row{


        display:flex;
        gap:5rem;
        width:100%;
        max-width: 20rem;
        flex-direction: column;
        flex-wrap: wrap;
        > h2 {
            font-size: 3rem;
    
        }
        .contact-email{
            font-size: 2rem;
        }
        .header-link{
            padding-top: .5rem;
        }
.links-container{
    display: flex;
    gap:5rem;
        .git-info{
            font-size: 2.4rem;
            padding-bottom: 1rem;
            color:#2a2c2c;
        }
        .linked-info{
            font-size: 2.4rem;
            color:#2a2c2c;
            

        }
        .git-info:hover{
            
            color: #8a63d2;
        }
        .linked-info:hover{
            color: #0a66c2;
        }

    }
}
  
}


@media (max-width:480px){
    .info-section{
        max-width:100%;
        border-top-left-radius:2em;
        border-bottom-left-radius: 2em;
        .info-row{
            .contact-email{
                font-size:1.5rem;
            }
        }

    }

}
