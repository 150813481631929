
    .category-container{
  
      
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      
    

      .background-image{
       
          max-width: 100%;
          min-height: auto;
          object-fit: cover;
          object-position: center;
        
      }

      .category-body-container{
        width:100%;
        min-height:2rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgb(36, 36, 36);
        opacity: 1;
        color:rgb(218, 218, 218);
      
      h2 {
        font-weight: bold;
        margin: 0 6px 0;
        font-size: 22px;
        margin-top:8px;
        margin-bottom:15px;
        
        
        

      }
  
      p {
        font-weight: lighter;
        font-size: 16px;
        margin-bottom:15px;
        
      }

      .visit-container{
       
        max-width:100rem;
        font-size:15px;
        display:flex;
       
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom:15px;
        gap:75px;
        a{
          text-decoration: none;
          color:white;
        
        }
        a:hover{
          cursor: pointer;
          color:#6c7d81;

        }

    }
  
}
    }

    .category-container:nth-child(1){
      opacity:0%;
      animation: .5s left-to-right-container ease-out forwards;
  
    }
    .category-container:nth-child(2){
      opacity:0%;
      animation: .5s left-to-right-container ease-out forwards;
      
    }
    .category-container:nth-child(3){
      opacity:0%;
      animation: .5s left-to-right-container ease-out forwards;
  
    }
    .category-container:nth-child(4){
      opacity:0%;
      animation: .5s right-to-left-container ease-out forwards;
      
    }

    @keyframes left-to-right-container{
      0%{
        transform: translateX(-10rem);
      }
      100%{
        opacity: 1;
        transform: translateX(0rem);
      }
      
    }
    @keyframes right-to-left-container{
      0%{
        transform: translateX(10rem);
      }
      100%{
        opacity: 1;
        transform: translateX(0rem);
      }
      
    }
    
    
    