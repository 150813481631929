.directory-container{
    
    max-width:100%;

    display:grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(auto-fill,minMax(400px,25%));
   gap:2rem;
   flex-grow:1
  
   
   }

//    @media (max-width:970px){
//     .directory-container{
//         margin-top:10rem;
//     }
//   }

// @media (max-width:480px){
//     .directory-container{
//         margin-top:10rem;
//     }

// }

  