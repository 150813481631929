.form-section{
    display:flex;
    flex-direction: column;
    width:100%;
    max-width: 50%;
    padding:1.5em;
    justify-content: space-between;



.error-paragraph{
    padding-top:1rem;
    color:rgb(192, 34, 34);
    font-size: 1rem;
}

    .input-row{
        display:flex;
        width:100%;
        justify-content: space-between;
        gap: 2.5rem;
    
        .first-input{
            display:flex;
            flex-direction: column;        
            row-gap: 1rem;
            width:100%;
        
        .input-container{      
            input{
                color:aliceblue;
                width:100%;
                border:none;
                outline: none;
                border-bottom: 1px solid black;
                background-color:#2a2c2c; 
                padding-bottom: .5rem;
            }

        }
    }
    .second-input{
        display:flex;
        flex-direction: column;
        row-gap: 1rem;
        width:100%;
    .input-container{
        input{
            color:aliceblue;
            width:100%;
            border:none;
            outline: none;
            border-bottom: 1px solid black;
            background-color:#2a2c2c; 
            padding-bottom: .5rem;
        }
        
    }
}               
}

.input-row-message{
display:flex;
flex-direction: column;
row-gap: 1rem;


.input-container{
    
    display:flex;
    justify-content: space-between;
    
    textarea{
        color:aliceblue;
        resize: none;
        // width: 100%;
        // max-width: 100%;
        // min-height:20rem;
        // border-bottom: 1px solid black;
     background-color:#2a2c2c; 
        padding: .5rem;
        width:100%;
       
    }
    

    
}




}
.btn-contact{
padding:1rem 2rem;
width:100%;

background-color: #60BBD0;
outline:none;
border:none;
font-family:inherit;
font-size:inherit;
color:white;
text-transform:uppercase;
cursor:pointer;
letter-spacing:2px;
margin-top: 40px;   
border-radius: 1em;
}    


 
}
.btn-contact:hover{
    animation: .2s button-animation forwards;
}

@media (max-width:480px){
    .form-section{
        max-width:100%;
        
    }
    .input-row{
        padding:1rem
    }

}
